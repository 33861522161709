<template>
  <div class="contatain">
    <div class="flex-row">
      <el-select
        size="mini"
        style="width: 130px"
        class="m-r-10"
        v-model="params.searchKey"
      >
        <el-option
          v-for="item in searchKeys"
          :key="item.value"
          :value="item.value"
          :label="item.label"
        ></el-option>
      </el-select>
      <el-input
        size="mini"
        style="width: 130px"
        class="m-r-10"
        v-model="params.searchValue"
      />
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-search"
        :loading="loading"
        @click="getSystemParams"
        >搜索</el-button
      >
      <el-button
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="openDialog('addSystemParams')"
        >添加</el-button
      >
    </div>
    <div class="content-wrap">
      <el-card class="row2-card">
        <el-table
          border
          :data="systemParamsList"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          max-height="650"
          v-loading="loading"
        >
          <el-table-column label="参数名称" width="200" prop="configName">
          </el-table-column>
          <el-table-column
            prop="configKey"
            label="参数键名"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="configValue"
            label="参数键值"
          ></el-table-column>
          <el-table-column prop="configType" label="参数类型"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="openDialog('addSystemParams', scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                @click="deleteSystemParams(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :total="count"
          :page-size="params.limit"
          :current-page="params.page"
          :page-sizes="[5, 10, 15, 20]"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </el-card>
    </div>
    <add-system-params
      ref="addSystemParams"
      @getSystemParams="getSystemParams"
      :editForm="editForm"
    />
  </div>
</template>
<script>
import { debounce, timestampToDate } from "@tools";
import addSystemParams from "./common/components/addSystemParams";
export default {
  components: {
    addSystemParams,
  },
  data() {
    this.searchKeys = [
      { value: "config_name", label: "参数名称" },
      { value: "config_type", label: "参数类型" },
    ];
    return {
      loading: false,
      systemParamsList: [],
      params: {
        limit: 10,
        page: 1,
        searchKey: "",
        searchValue: "",
      },
      count: 0,
      editForm: null,
    };
  },
  async created() {
    this.getSystemParams();
  },
  mounted() {},
  methods: {
    getSystemParams: debounce(async function () {
      this.loading = true;
      const data = await this.$api.systemConfig
        .getSystemParams(this.params)
        .finally(() => {
          this.loading = false;
        });
      this.systemParamsList = data.data;
      this.count = data.count;
    }, 600),
    handleSizeChange(size) {
      this.params.limit = size;
      this.getSystemParams();
    },
    handlePageChange(page) {
      this.params.page = page;
      this.getSystemParams();
    },
    transTime(time) {
      return timestampToDate(time);
    },
    openDialog(ref, form) {
      if (form) {
        const { configKey, configName, configValue, id, remark } = form;
        this.editForm = { configKey, configName, configValue, id, remark };
      } else {
        this.editForm = null;
      }
      this.$refs[ref].showDialog = true;
    },
    deleteSystemParams(id) {
      this.$confirm("此操作将永久删除该系统参数, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.$api.systemConfig.deleteSystemParams(id);
          this.getSystemParams();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
  },
  watch: {},
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "./common/style/dataForm.scss";
@import "./common/style/dataTable.scss";
</style>